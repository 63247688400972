import i18n from '@/i18n/index'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 12,
      prop: 'product_id',
      name: 'select',
      options: [],
      label: i18n.t('form.bundle.product'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }, {
      span: 12,
      prop: 'bundle_item_name',
      name: 'select',
      label: i18n.t('form.bundle.item'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }, {
      span: 12,
      prop: 'item_discount',
      name: 'input',
      type: 'number',
      label: i18n.t('form.bundle.itemDiscount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {}
    }, {
      span: 12,
      prop: 'item_type',
      name: 'select',
      options: [],
      label: i18n.t('form.bundle.itemType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }, {
      span: 12,
      prop: 'delete_date',
      name: 'date',
      type: 'datetime',
      label: i18n.t('form.bundle.delDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        format: 'yyyy-MM-DD HH:mm:ss',
        'value-format': 'yyyy-MM-DD HH:mm:ss'
      }
    }]
  }]
}
